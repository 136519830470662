import * as React from 'react';
import { SVG } from '../../utils/types';

const Monitor: React.FC<SVG> = ({
  width = 37,
  height = 36,
  color = '#E3AA99',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M23.415 18.675L21.75 15.36L17.085 24.675C16.845 25.185 16.32 25.5 15.75 25.5C15.18 25.5 14.655 25.185 14.415 24.675L11.82 19.5H3.75V27C3.75 28.65 5.1 30 6.75 30H30.75C32.4 30 33.75 28.65 33.75 27V19.5H24.75C24.18 19.5 23.655 19.185 23.415 18.675Z"
      fill="url(#paint0_linear_1_1218)"
    />
    <path
      d="M30.75 6H6.75C5.1 6 3.75 7.35 3.75 9V16.5H12.75C13.32 16.5 13.845 16.815 14.085 17.325L15.75 20.64L20.415 11.325C20.925 10.305 22.59 10.305 23.1 11.325L25.68 16.5H33.75V9C33.75 7.35 32.4 6 30.75 6Z"
      fill="url(#paint1_linear_1_1218)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_1218"
        x1={3.75}
        y1={6}
        x2={27.1646}
        y2={35.2683}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color} />
        <stop offset={1} stopColor="#A6604A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_1218"
        x1={3.75}
        y1={6}
        x2={27.1646}
        y2={35.2683}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color} />
        <stop offset={1} stopColor="#A6604A" />
      </linearGradient>
    </defs>
  </svg>
);
export default Monitor;
