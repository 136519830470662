import * as React from 'react';
import { SVG } from '../../utils/types';

const PatientBudLogo: React.FC<SVG> = ({
  width = 257,
  height = 53,
  // color = '#FFFFFF',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 257 53`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6621 37.1514C14.497 37.2512 14.3413 37.3582 14.1949 37.4715C13.0616 35.1343 12.4355 32.5682 12.4355 29.8759C12.4355 19.005 22.6427 10.1924 35.2339 10.1924C47.8251 10.1924 58.0323 19.005 58.0323 29.8759C58.0323 31.0267 57.9179 32.1545 57.6984 33.2511C57.3778 33.3405 57.0722 33.4686 56.7911 33.6386L43.328 41.7775C41.8994 42.6412 40.1353 42.9832 38.4207 42.7222L31.4841 41.9256C30.2981 41.745 29.4323 40.8628 29.3994 39.8338C29.3714 38.4558 30.795 37.4006 32.3608 37.6326L39.1049 38.6593C40.891 38.9312 42.5886 37.9049 42.9035 36.3629C43.1135 35.3349 38.7719 33.7136 29.8788 31.499C26.8782 31.0422 23.7887 31.634 21.2928 33.1429L14.6621 37.1514ZM40.6596 16.6999L39.6591 14.9342L38.66 16.6999L36.7855 17.641L38.66 18.5835L39.6591 20.3492L40.6596 18.5835L42.5333 17.6417L40.6596 16.6999ZM34.8587 20.7355L32.8372 17.1655L30.8164 20.7355L27.0265 22.6398L30.8164 24.544L32.838 28.114L34.8587 24.544L38.6487 22.6398L34.8587 20.7355ZM40.4178 23.5023L41.6806 25.7335L44.0493 26.9238L41.6806 28.114L40.4178 30.3453L39.1542 28.114L36.7855 26.9238L39.1542 25.7335L40.4178 23.5023Z"
      fill="#08B4BD"
    />
    <path
      d="M234.258 38.7308C232.58 38.7308 231.068 38.3385 229.722 37.5539C228.397 36.7692 227.349 35.6983 226.577 34.3411C225.804 32.9839 225.418 31.4676 225.418 29.7923C225.418 28.0746 225.804 26.5478 226.577 25.2118C227.349 23.8546 228.397 22.7836 229.722 21.999C231.068 21.2144 232.58 20.8221 234.258 20.8221C235.737 20.8221 237.05 21.1295 238.198 21.7445C239.345 22.3383 240.25 23.1442 240.913 24.1621L240.416 24.8937V14.2693H243.528V38.3491H240.515V34.691L240.913 35.2C240.295 36.3239 239.39 37.1934 238.198 37.8084C237.028 38.4233 235.715 38.7308 234.258 38.7308ZM234.523 35.868C235.648 35.868 236.653 35.6029 237.536 35.0727C238.418 34.5426 239.114 33.8216 239.621 32.9097C240.151 31.9766 240.416 30.9375 240.416 29.7923C240.416 28.626 240.151 27.5869 239.621 26.675C239.114 25.7419 238.418 25.0103 237.536 24.4802C236.653 23.95 235.648 23.6849 234.523 23.6849C233.419 23.6849 232.415 23.9606 231.51 24.512C230.627 25.0421 229.932 25.7631 229.424 26.675C228.916 27.5657 228.662 28.6048 228.662 29.7923C228.662 30.9375 228.916 31.9766 229.424 32.9097C229.932 33.8216 230.627 34.5426 231.51 35.0727C232.393 35.6029 233.397 35.868 234.523 35.868Z"
      fill="#08B4BD"
    />
    <path
      d="M213.264 38.7309C211.984 38.7309 210.825 38.4446 209.788 37.8721C208.772 37.2995 207.978 36.5042 207.404 35.4863C206.852 34.4472 206.576 33.2597 206.576 31.9237V21.2039H209.655V31.6056C209.655 32.4538 209.832 33.1961 210.185 33.8322C210.56 34.4684 211.068 34.9668 211.708 35.3273C212.37 35.6878 213.121 35.8681 213.959 35.8681C214.798 35.8681 215.538 35.6878 216.178 35.3273C216.84 34.9668 217.348 34.4472 217.701 33.7686C218.076 33.09 218.264 32.2842 218.264 31.3511V21.2039H221.376V38.3492H218.363V35.0092L218.86 35.2955C218.44 36.377 217.723 37.2253 216.707 37.8403C215.714 38.434 214.566 38.7309 213.264 38.7309Z"
      fill="#08B4BD"
    />
    <path
      d="M184.381 38.349V14.6509H194.744C196.223 14.6509 197.492 14.9054 198.551 15.4143C199.633 15.9233 200.461 16.6443 201.035 17.5774C201.631 18.4892 201.928 19.5814 201.928 20.8537C201.928 22.0201 201.608 23.0804 200.968 24.0347C200.35 24.9678 199.434 25.6994 198.22 26.2296L198.187 25.0526C199.247 25.4343 200.119 25.9433 200.803 26.5795C201.509 27.1944 202.039 27.9155 202.392 28.7425C202.745 29.5483 202.922 30.4072 202.922 31.3191C202.922 33.5033 202.193 35.2211 200.737 36.4722C199.28 37.7234 197.293 38.349 194.777 38.349H184.381ZM187.626 35.4861H194.909C196.344 35.4861 197.492 35.115 198.353 34.3728C199.214 33.6306 199.644 32.6233 199.644 31.3509C199.644 30.0785 199.214 29.0712 198.353 28.329C197.492 27.5656 196.344 27.1838 194.909 27.1838H187.626V35.4861ZM187.626 24.3846H194.678C195.87 24.3846 196.83 24.0559 197.558 23.3985C198.287 22.7199 198.651 21.861 198.651 20.8219C198.651 19.7404 198.287 18.9134 197.558 18.3408C196.83 17.7682 195.87 17.4819 194.678 17.4819H187.626V24.3846Z"
      fill="#08B4BD"
    />
    <path
      d="M178.147 38.54C176.271 38.54 174.814 38.0311 173.777 37.0131C172.739 35.9952 172.221 34.5638 172.221 32.7189V24.3848H169.075V21.1402H169.572C170.411 21.1402 171.062 20.9069 171.525 20.4404C171.989 19.9738 172.221 19.3376 172.221 18.5318V17.1958H175.962V21.1402H180.034V24.3848H175.962V32.5598C175.962 33.1536 176.061 33.6625 176.26 34.0867C176.459 34.4896 176.779 34.8077 177.22 35.041C177.662 35.253 178.236 35.3591 178.942 35.3591C179.118 35.3591 179.317 35.3484 179.538 35.3272C179.759 35.306 179.968 35.2848 180.167 35.2636V38.3491C179.858 38.3916 179.516 38.434 179.14 38.4764C178.765 38.5188 178.434 38.54 178.147 38.54Z"
      fill="#242E49"
    />
    <path
      d="M151 38.349V21.14H154.576V24.5118L154.146 24.0665C154.587 22.985 155.293 22.1685 156.265 21.6172C157.236 21.0446 158.361 20.7583 159.642 20.7583C160.966 20.7583 162.136 21.034 163.151 21.5853C164.166 22.1367 164.961 22.9001 165.535 23.8756C166.109 24.8511 166.396 25.9751 166.396 27.2474V38.349H162.688V28.2017C162.688 27.3323 162.522 26.6007 162.191 26.0069C161.86 25.3919 161.385 24.9254 160.767 24.6073C160.171 24.268 159.487 24.0983 158.715 24.0983C157.942 24.0983 157.247 24.268 156.629 24.6073C156.033 24.9254 155.569 25.3919 155.238 26.0069C154.907 26.6219 154.742 27.3535 154.742 28.2017V38.349H151Z"
      fill="#242E49"
    />
    <path
      d="M139.105 38.7307C137.317 38.7307 135.728 38.3384 134.337 37.5537C132.969 36.7479 131.898 35.6664 131.126 34.3092C130.353 32.9308 129.967 31.3933 129.967 29.6968C129.967 27.9579 130.353 26.4204 131.126 25.0844C131.92 23.7484 132.98 22.6987 134.304 21.9353C135.628 21.1506 137.129 20.7583 138.807 20.7583C140.153 20.7583 141.356 20.981 142.416 21.4263C143.475 21.8716 144.369 22.4866 145.097 23.2713C145.826 24.0347 146.378 24.9147 146.753 25.9114C147.15 26.9081 147.349 27.9685 147.349 29.0924C147.349 29.3681 147.338 29.6544 147.316 29.9513C147.294 30.2481 147.25 30.5238 147.183 30.7783H132.913V27.9154H145.064L143.277 29.2196C143.497 28.1805 143.42 27.2581 143.045 26.4522C142.692 25.6252 142.14 24.9784 141.389 24.5118C140.661 24.0241 139.8 23.7802 138.807 23.7802C137.814 23.7802 136.931 24.0241 136.158 24.5118C135.386 24.9784 134.79 25.657 134.37 26.5476C133.951 27.4171 133.785 28.4774 133.874 29.7286C133.763 30.8949 133.929 31.9128 134.37 32.7823C134.834 33.6518 135.474 34.3304 136.291 34.8181C137.129 35.3059 138.078 35.5497 139.138 35.5497C140.219 35.5497 141.135 35.3165 141.886 34.8499C142.658 34.3834 143.265 33.779 143.707 33.0368L146.753 34.4682C146.4 35.2741 145.848 36.0057 145.097 36.6631C144.369 37.2993 143.486 37.8082 142.449 38.1899C141.433 38.5504 140.319 38.7307 139.105 38.7307Z"
      fill="#242E49"
    />
    <path
      d="M122.58 38.349V21.14H126.321V38.349H122.58ZM122.58 18.7861V14.6509H126.321V18.7861H122.58Z"
      fill="#242E49"
    />
    <path
      d="M116.909 38.54C115.033 38.54 113.576 38.0311 112.539 37.0131C111.501 35.9952 110.982 34.5638 110.982 32.7189V24.3848H107.837V21.1402H108.334C109.173 21.1402 109.824 20.9069 110.287 20.4404C110.751 19.9738 110.982 19.3376 110.982 18.5318V17.1958H114.724V21.1402H118.796V24.3848H114.724V32.5598C114.724 33.1536 114.823 33.6625 115.022 34.0867C115.22 34.4896 115.54 34.8077 115.982 35.041C116.423 35.253 116.997 35.3591 117.704 35.3591C117.88 35.3591 118.079 35.3484 118.3 35.3272C118.52 35.306 118.73 35.2848 118.929 35.2636V38.3491C118.62 38.3916 118.277 38.434 117.902 38.4764C117.527 38.5188 117.196 38.54 116.909 38.54Z"
      fill="#242E49"
    />
    <path
      d="M95.7075 38.7307C94.4935 38.7307 93.423 38.5292 92.4959 38.1263C91.591 37.7022 90.8846 37.1296 90.377 36.4086C89.8693 35.6664 89.6155 34.7969 89.6155 33.8002C89.6155 32.8671 89.8252 32.0295 90.2445 31.2873C90.686 30.545 91.3592 29.9195 92.2642 29.4105C93.1692 28.9015 94.3059 28.541 95.6744 28.329L101.899 27.3429V30.1739L96.4028 31.0964C95.4095 31.2661 94.6811 31.5735 94.2176 32.0189C93.7541 32.443 93.5223 32.9944 93.5223 33.673C93.5223 34.3304 93.7761 34.8711 94.2838 35.2953C94.8136 35.6982 95.4868 35.8996 96.3034 35.8996C97.3188 35.8996 98.2017 35.6876 98.9521 35.2635C99.7247 34.8393 100.321 34.2774 100.74 33.5775C101.159 32.8565 101.369 32.0613 101.369 31.1918V26.7703C101.369 25.9221 101.038 25.2328 100.376 24.7027C99.7357 24.1513 98.8749 23.8756 97.7933 23.8756C96.8001 23.8756 95.9282 24.1301 95.1778 24.6391C94.4494 25.1268 93.9086 25.763 93.5554 26.5476L90.4432 25.0526C90.7743 24.2043 91.3151 23.4621 92.0655 22.8259C92.816 22.1685 93.6879 21.6596 94.6811 21.2991C95.6965 20.9386 96.767 20.7583 97.8927 20.7583C99.3053 20.7583 100.552 21.0128 101.634 21.5217C102.738 22.0307 103.587 22.7411 104.183 23.653C104.801 24.5436 105.11 25.5827 105.11 26.7703V38.349H101.535V35.2316L102.296 35.3271C101.877 36.0269 101.336 36.6313 100.674 37.1402C100.034 37.6492 99.2943 38.0415 98.4555 38.3172C97.6388 38.5929 96.7228 38.7307 95.7075 38.7307Z"
      fill="#242E49"
    />
    <path
      d="M70.4678 38.3492V14.6511H79.4402C81.0957 14.6511 82.5525 14.948 83.8106 15.5418C85.0908 16.1144 86.0841 16.9626 86.7904 18.0866C87.4967 19.1893 87.8499 20.5147 87.8499 22.0627C87.8499 23.5896 87.4857 24.9044 86.7573 26.0071C86.051 27.1099 85.0687 27.9581 83.8106 28.5519C82.5525 29.1457 81.0957 29.4426 79.4402 29.4426H74.3746V38.3492H70.4678ZM74.3746 26.1026H79.5396C80.4225 26.1026 81.195 25.9329 81.8572 25.5936C82.5194 25.2543 83.0381 24.7878 83.4133 24.194C83.7885 23.579 83.9761 22.858 83.9761 22.0309C83.9761 21.2039 83.7885 20.4935 83.4133 19.8997C83.0381 19.2847 82.5194 18.8182 81.8572 18.5001C81.195 18.1608 80.4225 17.9911 79.5396 17.9911H74.3746V26.1026Z"
      fill="#242E49"
    />
  </svg>
);
export default PatientBudLogo;
