import { ISidebarData } from '../utils/types';

export const sidebarBodyIcons: Array<ISidebarData> = [
  {
    id: 1,
    iconName: 'Home',
    title: 'Home',
    notification: '',
    path: '/',
    isActive: true,
  },
  {
    id: 2,
    iconName: 'Sandra',
    title: 'Ask Sandra',
    notification: '',
    path: '/asksandra',
    isActive: true,
  },
  {
    id: 3,
    iconName: 'Bill',
    title: 'Bills & Estimates',
    notification: '',
    path: '/bills',
    isActive: false,
  },
  // {
  // 	id: 4,
  // 	iconName: 'Dollar',
  // 	title: 'Offers',
  // 	notification: '',
  // 	path: '/offers',
  // 	isActive: false,
  // },
  // {
  // 	id: 5,
  // 	iconName: 'Wallet',
  // 	title: 'Wallet',
  // 	notification: '',
  // 	path: '/wallet',
  // 	isActive: false,
  // },
  {
    id: 6,
    iconName: 'Insurance',
    title: 'Insurance',
    notification: '',
    path: '/insurance',
    isActive: true,
  },
  {
    id: 7,
    iconName: 'Gear',
    title: 'Setting',
    notification: '',
    path: '/setting',
    isActive: true,
  },
];

export const sidebarFootericons = [
  {
    id: 8,
    iconName: 'SignOut',
    title: 'Log Out',
    notification: '',
    path: '',
  },
];
