import React, { useState } from 'react';
import {
  UserEligibilityType,
  getPayers,
  postUserEligibilityData,
} from '../../utils/utils';
import SmartDropdown from './SmartDropDown';
import LoadingOverlay from './LoadingOverlay';
type UserEligibilityFormType = {
  callback?: Function;
};
export default function UserEligibilityForm({
  callback,
}: UserEligibilityFormType) {
  const [loading, setLoading] = useState(false);
  const [payers, setpayers] = useState([]);

  React.useEffect(() => {
    setLoading(true);
    const response = getPayers();
    response
      .then((responseData) => {
        const mapData = responseData.map((data: any) => data.payerName);
        setpayers(mapData || []);
        setLoading(false);
      })
      .catch((error) => {
        setpayers([]);
        setLoading(false);
        setMessage({
          type: 'error',
          text: 'Something went wrong. Please try after sometime',
        });
      });
  }, []);
  const [formData, setFormData] = useState<UserEligibilityType>({
    membershipId: '',
    insuranceCompanyName: '',
    primaryInsuranceSubscriber: false,
    dateOfBirth: '',
  });
  const [message, setMessage] = useState<{
    type: 'success' | 'error';
    text: string;
  } | null>(null);
  // @ts-ignore
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // @ts-ignore
  const handleDateChange = (e) => {
    const value = e.target.value;
    const formattedDate = value;
    setFormData((prev) => ({ ...prev, dateOfBirth: formattedDate }));
  };

  const formatDateToYYYYMMDD = (date: any) => {
    const [year, month, day] = date.split('-');
    return `${year}-${month}-${day}`;
  };
  const onInsuranceChange = (value: string) => {
    setFormData((prev) => ({ ...prev, insuranceCompanyName: value }));
  };

  // @ts-ignore
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = postUserEligibilityData({
        ...formData,
        dateOfBirth: formatDateToYYYYMMDD(formData.dateOfBirth),
      });
      response
        .then((data: any) => {
          setLoading(false);
          setMessage({
            type: 'success',
            text: 'Insurance Information Updated Successfully.',
          });
          callback && callback(formData);
        })
        .catch((err: any) => {
          setLoading(false);
          setMessage({
            type: 'error',
            text: 'Failed to update insurance details.',
          });
        });
    } catch (err) {
      console.error(err);
      setMessage({
        type: 'error',
        text: 'Failed to update insurance details.',
      });
    }
  };

  return (
    <div
      className={`bg-gray-100 flex sm:items-center items-start pt-6 ${!callback && `min-h-screen justify-center`}`}
    >
      {loading && <LoadingOverlay />}
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full space-y-6"
      >
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          Insurance Details Form
        </h2>
        {/* Display Message */}
        {message && (
          <div
            className={`p-4 rounded-md mb-4 ${
              message.type === 'success'
                ? 'bg-green-100 text-green-700'
                : 'bg-red-100 text-red-700'
            }`}
          >
            {message.text}
          </div>
        )}
        {/* Membership ID */}
        <div>
          <label
            htmlFor="membershipId"
            className="block text-sm font-medium text-gray-700"
          >
            Membership ID
          </label>
          <input
            type="text"
            id="membershipId"
            name="membershipId"
            value={formData.membershipId}
            onChange={handleChange}
            placeholder="Enter Membership ID"
            className="mt-1 w-full p-2 border rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
            required
          />
        </div>
        <SmartDropdown options={payers} onSelect={onInsuranceChange} />

        {/* Primary Insurance Subscriber */}
        <div>
          <label
            htmlFor="primaryInsuranceSubscriber"
            className="block text-sm font-medium text-gray-700"
          >
            Primary Insurance Subscriber
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="primaryInsuranceSubscriber"
              name="primaryInsuranceSubscriber"
              checked={formData.primaryInsuranceSubscriber}
              onChange={handleChange}
              className="h-4 w-4 text-teal-600 border-gray-300 rounded focus:ring-teal-500"
            />
            <span className="text-sm text-gray-600">
              Yes, I am the subscriber
            </span>
          </div>
        </div>

        {/* Date of Birth */}
        <div>
          <label
            htmlFor="dateOfBirth"
            className="block text-sm font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleDateChange}
            className="mt-1 w-full p-2 border rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
            required
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-teal-600 text-white py-2 px-4 rounded-md shadow hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
