const MessageWithLogin = () => {
  return (
    <div
      className="flex flex-col ml-4 mr-4 md:flex-row items-center justify-between p-4 rounded-lg max-w-[840px] mx-auto"
      style={{
        background:
          'linear-gradient(130.43deg, #006A6F -14.67%, #2A334B 72.91%)',
      }}
    >
      {/* Text Section */}
      <div className="text-white text-sm md:text-base font-medium text-center md:text-left max-w-full md:max-w-[60%] mb-4 md:mb-0">
        Take charge of your healthcare finances today! Get instant, personalized
        answers to all your billing and insurance questions. Logging in is quick
        and easy—start now and take the guesswork out of healthcare!
      </div>

      {/* Button Section */}
      <button
        className="flex items-center justify-center font-bold py-2 px-4 text-base md:text-lg w-full md:w-[152px] bg-teal-40 text-white rounded-md transition"
        onClick={() => {
          window.location.href = '/login';
        }}
      >
        Sign In →
      </button>
    </div>
  );
};

export default MessageWithLogin;
