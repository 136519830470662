import * as React from 'react';
import { SVG } from '../../utils/types';

const Matrix: React.FC<SVG> = ({
  width = 32,
  height = 33,
  color = '#9EA7B8',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M26.5 13.8333H24.1666C23.3382 13.8333 22.6666 14.5049 22.6666 15.3333V27C22.6666 27.8284 23.3382 28.5 24.1666 28.5H26.5C27.3284 28.5 28 27.8284 28 27V15.3333C28 14.5049 27.3284 13.8333 26.5 13.8333Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M7.83333 19.1667H5.5C4.67157 19.1667 4 19.8383 4 20.6667V27C4 27.8284 4.67157 28.5 5.5 28.5H7.83333C8.66176 28.5 9.33333 27.8284 9.33333 27V20.6667C9.33333 19.8383 8.66176 19.1667 7.83333 19.1667Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M17.1667 7.16669H14.8334C14.0049 7.16669 13.3334 7.83826 13.3334 8.66669V27C13.3334 27.8284 14.0049 28.5 14.8334 28.5H17.1667C17.9951 28.5 18.6667 27.8284 18.6667 27V8.66669C18.6667 7.83826 17.9951 7.16669 17.1667 7.16669Z"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);
export default Matrix;
