import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarLogo from '../../assets/SVGs/SidebarLogo';
import { RootState } from '../../store';
import {
  hideSideBar,
  openSideBar,
  setActiveSidebarIconId,
} from '../../store/sidebarSlice';
import { sidebarBodyIcons } from '../../constants/data';
import IconWithText from './IconWithText';
import MobileHeader from './MobileHeader';
import AuthButton from '../login/auth-button';

const Sidebar: React.FC = () => {
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const isTablet = window.innerWidth <= 1024;
  const activeSidebarIconId = useSelector(
    (state: RootState) => state.sidebar.activeSidebarIconId
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const pathToIconIdMap: { [key: string]: number } = {
    '/': 1,
    '/asksandra': 2,
  };

  useEffect(() => {
    if (isTablet) {
      dispatch(hideSideBar());
    } else if (window.innerWidth > 1024) {
      dispatch(openSideBar());
    }
  }, [dispatch]);

  useEffect(() => {
    const currentIconId = pathToIconIdMap[location.pathname];
    if (currentIconId && currentIconId !== activeSidebarIconId) {
      dispatch(setActiveSidebarIconId(currentIconId));
    }
  }, [location.pathname, activeSidebarIconId, dispatch]);

  if (!isOpen) {
    return <></>;
  }

  const onIconClickHandler = (icon: any) => {
    dispatch(setActiveSidebarIconId(icon.id));
    if (icon.path) {
      navigate(icon.path);
    }
    if (isTablet) {
      dispatch(hideSideBar());
    }
  };

  return (
    <div className="fixed h-screen inset-0 z-40 flex md:relative md:inset-auto">
      {/* Overlay for Mobile */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50 md:hidden"
        onClick={() => dispatch(hideSideBar())}
      ></div>
      <div
        className={`bg-white h-full w-full md:w-[223px] transition-transform duration-300 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0`}
      >
        <div
          className="hidden md:block ml-3 absolute top-5 flex justify-center items-center cursor-pointer"
          onClick={() => {
            navigate('/');
          }}
        >
          <SidebarLogo />
        </div>

        <MobileHeader />

        <div className="w-full h-full flex flex-col justify-start mt-5 md:mt-0 md:justify-center md:items-center">
          <div className="flex flex-col w-full">
            {sidebarBodyIcons.map((icon, index) => (
              <div key={index} className="w-full px-3">
                <IconWithText
                  clickHandler={() => icon.isActive && onIconClickHandler(icon)}
                  iconName={icon.iconName}
                  text={icon.title}
                  isActive={icon.isActive}
                  iconColor={activeSidebarIconId === icon.id ? '#08B4BD' : ''}
                  className={`mb-10  md:mb-6 w-full rounded-xl p-[4px] px-2 cursor-pointer ${
                    activeSidebarIconId === icon.id ? 'bg-[#DCE1E8]' : ''
                  }`}
                  textClass={
                    activeSidebarIconId === icon.id
                      ? 'text-[#242E49] font-medium'
                      : ''
                  }
                  notificationNumber={icon.notification}
                />
              </div>
            ))}
          </div>
        </div>

        {/* { Logout Option } */}
        <AuthButton />
      </div>
    </div>
  );
};

export default Sidebar;
