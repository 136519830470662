import React, { useRef } from 'react';
import { useChatContext } from '../../context/ChatContext';

interface MessageInputProps {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  onSendMessage: (message: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({
  text,
  setText,
  onSendMessage,
}) => {
  const { sendMessageWithResponse } = useChatContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    autoResize();
  };

  const handleSendMessage = () => {
    if (text.trim()) {
      sendMessageWithResponse(text);
    }
    setText('');
    autoResize();
  };
  const autoResize = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > 100) {
        textareaRef.current.style.height = '100px';
      }
    }
  };
  return (
    <div className="px-[25px] md:px-[100px] space-x-2 absolute inset-x-0 bottom-[30px]">
      <div className="bg-white flex items-center rounded-lg px-5">
        {/* <button className="p-2 bg-gray-300">
                <svg className="h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2C6.485 2 2 6.485 2 12s4.485 10 10 10 10-4.485 10-10S17.515 2 12 2zm1 17v-4h-2v4h-1v-4H8v4H7v-4H6v4h1v-5c0-.553.447-1 1-1h3c.553 0 1 .447 1 1v5h1v-5c0-.553.447-1 1-1h3c.553 0 1 .447 1 1v5h1v-4h-1v4h-1v-4h-2v4h-1z"/>
                </svg>
            </button> */}
        <textarea
          type="text"
          ref={textareaRef}
          value={text}
          // @ts-ignore
          onChange={handleInputChange}
          placeholder="Ask a question..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          className="w-full outline-none text-gray-600 placeholder-gray-400 focus:ring-0"
          rows={1}
          style={{
            overflowY: 'auto', // Show scrollbar when exceeding max height
            maxHeight: '100px', // Limit height to 200px
          }}
        />
        {/* <input
                type="text"
                value={text}
                onChange={handleInputChange}
                placeholder="Ask a question..."
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSendMessage();
                    }
                }}
                className="w-full outline-none text-gray-600 placeholder-gray-400 focus:ring-0"
            /> */}
        <button
          className="p-2  rounded-full text-white"
          onClick={handleSendMessage}
        >
          <div
            className={`flex items-center justify-center w-10 h-10 rounded-lg ${text?.length > 0 ? 'bg-[#0699A1]' : 'bg-gray-200'}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              className="w-5 h-5 text-gray-500"
            >
              <path
                d="M12 20V7m0 0l-5 5m5-5l5 5"
                stroke={`${text.length > 0 ? 'white' : 'black'}`}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
