import React, { useState, useEffect } from 'react';
// import SidebarLogo from '../../assets/SVGs/SidebarLogo';
import { useNavigate } from 'react-router-dom';
// import { goBack } from '../../utils/utils';
import LoginSideImg from '../../assets/SVGs/LogInSideImage';
import {
  SignUpFormType,
  signUpApi,
  isValidSignUpFormData,
  confirmSignUpOtp,
} from '../../utils/utils';
const Signup: React.FC = () => {
  const [formData, setFormData] = useState<SignUpFormType>({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpValidated, setOtpValidated] = useState(false);
  const [isValidInput, setIsValidInput] = useState(false);
  // const [invalidReason, setinvalidReason] = useState("");
  const [message, setMessage] = useState<{
    type: 'success' | 'error';
    text: string;
  } | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setFormData({
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
    });
    setOtpValidated(false);
    setIsValidInput(false);
    setIsOtpSent(false);
    setOtp('');
  }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const isValidData = isValidSignUpFormData(formData) === null ? true : false;
    if (isValidData !== isValidInput) {
      setIsValidInput(isValidData);
    }
  }, [formData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isValidInput) {
      signUpApi(formData)
        .then((response) => {
          if (response.success === true) {
            setIsOtpSent(true);
            setMessage({
              type: 'success',
              text: 'OTP sent successfully! Please verify your email with the OTP sent to your email address.',
            });
          } else {
            setMessage({
              type: 'error',
              text: 'Failed to create account. Please try again later.',
            });
          }
        })
        .catch((error) => {
          setMessage({
            type: 'error',
            text:
              'Failed to create account. Please try again later. ' +
              error.message,
          });
        });
    } else {
      setMessage({
        type: 'error',
        text: 'Failed to create account. Please try again later. ',
      });
    }
  };

  const handleOtpSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      confirmSignUpOtp(formData.userName, otp)
        .then((response) => {
          if (response.success === true) {
            setIsOtpSent(false);
            setMessage({
              type: 'success',
              text: 'Account created successfully!',
            });
            setOtpValidated(true);
            setTimeout(
              () =>
                navigate('/login', {
                  state: {
                    signUp: true,
                    date: Date.now(),
                  },
                }),
              2000
            );
          } else {
            setMessage({
              type: 'error',
              text: 'Failed to create account. Please try again later.',
            });
          }
        })
        .catch((error) => {
          setMessage({
            type: 'error',
            text:
              'Failed to create account. Please try again later. ' +
              error.message,
          });
        });
    } catch (error) {
      setMessage({
        type: 'error',
        text: 'Failed to verify account. Please try again later. ',
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-1 md:flex-row">
        {/* Left Side: Form */}
        <div className="flex flex-col justify-start overflow-auto md:justify-center w-full md:w-[55%] bg-[#F2F5F9] p-6 lg:p-12">
          <div className="sm:px-[32px] md:px-[64px]">
            {/* <div className="flex justify-between items-center mb-8">
              <span
                className="text-gray-600 cursor-pointer text-2xl font-bold"
                onClick={goBack}
              >
                ←
              </span>
              <SidebarLogo />
            </div> */}

            <h2 className="text-left text-2xl font-semibold leading-9 text-[#29406B]">
              {isOtpSent ? 'Verify Your Account' : 'Create an Account'}
            </h2>
          </div>

          <div className="mt-6 sm:px-[32px] md:px-[64px]">
            {message && (
              <div
                className={`p-4 rounded-md mb-4 ${
                  message.type === 'success'
                    ? 'bg-green-100 text-green-700'
                    : 'bg-red-100 text-red-700'
                }`}
              >
                {message.text}
              </div>
            )}

            {!isOtpSent && !otpValidated ? (
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="flex-1">
                    <label
                      htmlFor="firstName"
                      className="font-semibold text-base text-[#29406B]"
                    >
                      First Name*
                    </label>
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      required
                      className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                    />
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="lastName"
                      className="font-semibold text-base text-[#29406B]"
                    >
                      Last Name*
                    </label>
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                      className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row gap-4">
                  <div className="flex-1">
                    <label
                      htmlFor="username"
                      className="font-semibold text-base text-[#29406B]"
                    >
                      Username*
                    </label>
                    <input
                      id="username"
                      name="userName"
                      type="text"
                      value={formData.userName}
                      onChange={handleInputChange}
                      required
                      className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                    />
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="phoneNumber"
                      className="font-semibold text-base text-[#29406B]"
                    >
                      Phone Number*
                    </label>
                    <input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                      className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="font-semibold text-base text-[#29406B]"
                  >
                    Email*
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                  />
                </div>

                <div className="flex flex-col md:flex-row gap-4">
                  <div className="flex-1">
                    <label
                      htmlFor="password"
                      className="font-semibold text-base text-[#29406B]"
                    >
                      Password*
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                      className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                    />
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="confirmPassword"
                      className="font-semibold text-base text-[#29406B]"
                    >
                      Confirm Password*
                    </label>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      required
                      className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className={`flex px-4 py-3 w-full justify-center rounded-md px-3 py-1.5 text-xl font-semibold text-[#FFFFFF] shadow-sm
    ${isValidInput ? 'bg-[#08B4BD] opacity-100' : 'bg-[#08B4BD] opacity-50 cursor-not-allowed'}`}
                  disabled={!isValidInput}
                >
                  Create Account
                </button>

                <p className="mt-4 text-center text-lg font-semibold">
                  Already have an account?{' '}
                  <a
                    href="/login"
                    className="text-lg text-[#3BA3AB] underline font-semibold cursor-pointer"
                  >
                    Sign in
                  </a>
                </p>
              </form>
            ) : (
              !otpValidated && (
                <form className="space-y-6" onSubmit={handleOtpSubmit}>
                  <div>
                    <label
                      htmlFor="otp"
                      className="font-semibold text-base text-[#29406B]"
                    >
                      Enter OTP*
                    </label>
                    <input
                      id="otp"
                      name="otp"
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                      className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                    />
                  </div>

                  <button
                    type="submit"
                    className="flex px-4 py-3 w-full justify-center rounded-md bg-[#08B4BD] px-3 py-1.5 text-xl font-semibold text-[#FFFFFF] shadow-sm"
                  >
                    Verify OTP
                  </button>
                </form>
              )
            )}
          </div>
        </div>

        {/* Right Side: Illustration */}
        <div className="hidden md:flex md:w-[45%] items-center justify-end bg-[#242E49] h-screen">
          <LoginSideImg />
        </div>
      </div>
    </div>
  );
};

export default Signup;
