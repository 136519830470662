import * as React from 'react';
import { SVG } from '../../utils/types';

const PlantPot2: React.FC<SVG> = ({
  width = 123,
  height = 235,
  // color = '#FFFFFF',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M112.357 162.605L100.273 242.002H30.061L13.7693 163.745L112.357 162.605Z"
      fill="#08B4BD"
    />
    <path
      d="M23.5908 213.491C23.5908 213.726 41.5754 213.91 63.8172 213.91C86.0591 213.91 104.044 213.726 104.044 213.491C104.044 213.256 86.0591 213.089 63.8172 213.089C41.5754 213.089 23.5908 213.273 23.5908 213.491Z"
      fill="#FAFAFA"
    />
    <path
      d="M23.3228 209.586C23.3228 209.821 41.4246 210.005 63.767 210.005C86.1095 210.005 104.211 209.821 104.211 209.586C104.211 209.351 86.1095 209.184 63.767 209.184C41.4246 209.184 23.3228 209.368 23.3228 209.586Z"
      fill="#FAFAFA"
    />
    <path
      d="M23.4568 205.178C23.4568 205.412 41.5587 205.597 63.9011 205.597C86.2435 205.597 104.345 205.412 104.345 205.178C104.345 204.943 86.2435 204.759 63.9011 204.759C41.5587 204.759 23.4568 204.943 23.4568 205.178Z"
      fill="#FAFAFA"
    />
    <path
      d="M92.2107 237.476C93.184 234.184 93.9013 230.822 94.3561 227.42C95.0742 224.081 95.5226 220.69 95.697 217.279C94.7092 220.567 93.9917 223.931 93.5516 227.336C92.8236 230.673 92.3751 234.065 92.2107 237.476Z"
      fill="#FAFAFA"
    />
    <path
      d="M87.4337 236.638C87.911 233.406 88.202 230.149 88.3053 226.883C88.6595 223.633 88.8107 220.364 88.7579 217.095C87.9923 220.281 87.5653 223.54 87.484 226.816C87.1102 230.079 87.0933 233.372 87.4337 236.638Z"
      fill="#FAFAFA"
    />
    <path
      d="M78.7178 236.638C80.3585 230.062 81.1527 223.303 81.0811 216.525C80.4424 219.849 79.9891 223.207 79.7234 226.581C79.2078 229.913 78.8721 233.27 78.7178 236.638Z"
      fill="#FAFAFA"
    />
    <path
      d="M72.5999 237.057C73.6772 230.318 73.8968 223.47 73.2535 216.676C72.8902 220.06 72.7223 223.463 72.7507 226.866C72.4981 230.257 72.4478 233.66 72.5999 237.057Z"
      fill="#FAFAFA"
    />
    <path
      d="M61.7895 236.638C62.3206 233.448 62.573 230.218 62.5437 226.984C62.7856 223.763 62.752 220.527 62.4432 217.312C61.933 220.505 61.6863 223.734 61.7057 226.967C61.4739 230.188 61.5019 233.422 61.7895 236.638Z"
      fill="#FAFAFA"
    />
    <path
      d="M53.8948 237.058C54.293 233.721 54.4052 230.357 54.23 227.001C54.3398 223.669 54.1716 220.333 53.7272 217.028C53.351 220.367 53.2445 223.73 53.4087 227.085C53.2988 230.417 53.4614 233.752 53.8948 237.058Z"
      fill="#FAFAFA"
    />
    <path
      d="M46.4863 235.985C46.5698 232.499 46.368 229.012 45.8829 225.559C45.6806 222.081 45.2099 218.624 44.475 215.218C44.3912 218.704 44.593 222.19 45.0784 225.643C45.2807 229.121 45.7514 232.579 46.4863 235.985Z"
      fill="#FAFAFA"
    />
    <path
      d="M36.9328 234.945C36.9203 231.952 36.526 228.972 35.7595 226.079C35.2548 223.13 34.3761 220.257 33.1448 217.531C33.5896 220.472 34.1939 223.387 34.955 226.263C35.4448 229.193 36.1052 232.092 36.9328 234.945Z"
      fill="#FAFAFA"
    />
    <path
      d="M43.7043 5.92334C43.6838 6.05665 43.6838 6.19228 43.7043 6.32559C43.7043 6.62729 43.8887 6.99605 44.0228 7.4486C44.358 8.48778 44.7938 9.91244 45.3637 11.7226C45.9335 13.5328 46.6878 15.8626 47.4253 18.5276C48.1628 21.1926 49.1014 24.1928 49.9562 27.5786C50.811 30.9643 51.8167 34.6349 52.6882 38.6408C53.5598 42.6467 54.4816 46.904 55.4035 51.4294C57.0796 60.4804 58.6216 70.4867 59.6943 81.0461C60.767 91.6056 61.2028 101.746 61.3704 110.965C61.3704 115.557 61.3704 119.932 61.2531 124.038C61.1358 128.145 60.9514 131.933 60.7503 135.419C60.5491 138.905 60.2809 142.023 60.0631 144.788C59.8452 147.554 59.6105 149.917 59.3759 151.861C59.1412 153.806 59.0239 155.214 58.9233 156.32C58.9233 156.789 58.8898 157.175 58.8227 157.476C58.8227 157.744 58.8227 157.879 58.8227 157.879C58.879 157.757 58.913 157.626 58.9233 157.493C58.9233 157.191 59.0239 156.806 59.1077 156.337C59.2418 155.264 59.4597 153.772 59.7111 151.895C59.9625 150.018 60.281 147.587 60.5324 144.838C60.7838 142.09 61.1525 138.939 61.3537 135.452C61.5548 131.966 61.8397 128.161 61.9403 124.055C62.0409 119.948 62.1247 115.574 62.1582 110.948C62.0576 101.729 61.6051 91.572 60.4821 80.9791C59.3591 70.3861 57.8339 60.3463 56.1075 51.2786C55.1689 46.7531 54.3308 42.4623 53.3251 38.4732C52.3195 34.4841 51.4144 30.7799 50.4925 27.4277C49.5707 24.0755 48.6321 21.0418 47.8275 18.3935C47.023 15.7453 46.2855 13.4658 45.6318 11.6891C44.9782 9.91246 44.5089 8.48778 44.1569 7.4486C43.9893 7.01281 43.8552 6.64408 43.7546 6.35914C43.7474 6.21294 43.7306 6.06735 43.7043 5.92334Z"
      fill="#242E49"
    />
    <path
      d="M56.3753 55.0833C54.4421 49.3572 50.579 44.481 45.4471 41.289C43.771 40.2163 41.5251 39.3447 39.6814 40.2331C37.2343 41.4231 37.0331 44.8591 37.7706 47.4905C39.0439 51.9498 41.8903 55.7966 45.7824 58.3182C49.2029 60.8522 53.4868 61.9306 57.6994 61.3184"
      fill="#242E49"
    />
    <path
      d="M60.6329 88.2869C58.8227 81.4316 53.3586 71.6935 47.6767 67.4362C46.8063 66.7264 45.8036 66.1965 44.7267 65.8774C44.1875 65.7155 43.6161 65.6921 43.0654 65.8092C42.5147 65.9264 42.0023 66.1805 41.5757 66.5478C41.0913 67.1907 40.7735 67.9433 40.6506 68.7388C40.5278 69.5343 40.6037 70.3478 40.8717 71.1068C42.0365 76.1053 44.5692 80.681 48.1865 84.3219C51.8038 87.9629 56.3629 90.5255 61.3536 91.7228"
      fill="#242E49"
    />
    <path
      d="M61.8061 117.501C58.9874 110.96 54.4743 105.29 48.7326 101.075C48.1257 100.502 47.4086 100.058 46.625 99.7695C45.8413 99.4814 45.0074 99.3554 44.1736 99.3991C41.6929 99.8349 40.57 102.869 40.8214 105.383C41.2739 110.545 45.2295 114.702 49.3024 117.903C53.041 120.496 57.104 122.587 61.3871 124.122"
      fill="#242E49"
    />
    <path
      d="M61.1524 138.519C58.428 133.523 54.6264 129.195 50.0231 125.848C49.0362 125.098 47.8887 124.588 46.6709 124.356C46.0521 124.226 45.4089 124.273 44.8157 124.492C44.2226 124.712 43.7036 125.095 43.3187 125.597C42.1958 127.273 43.3187 129.452 44.4585 131.044C48.3829 136.506 53.9412 140.579 60.3312 142.676"
      fill="#242E49"
    />
    <path
      d="M61.7055 129.954C66.4996 124.08 71.8303 118.666 77.6285 113.78C78.015 113.333 78.5074 112.989 79.0608 112.782C79.6142 112.574 80.2109 112.508 80.7963 112.59C81.2195 112.765 81.5827 113.06 81.8415 113.438C82.1004 113.816 82.2439 114.261 82.2545 114.719C82.2546 115.641 82.0484 116.551 81.6511 117.384C79.7489 121.607 76.9198 125.347 73.374 128.327C69.8282 131.307 65.6567 133.45 61.1692 134.597"
      fill="#242E49"
    />
    <path
      d="M61.1858 102.651L73.6895 87.4154C74.7893 85.8887 76.165 84.5812 77.7457 83.5604C78.55 83.0756 79.4715 82.8193 80.4107 82.8193C81.3499 82.8193 82.2713 83.0756 83.0756 83.5604C83.7955 84.1593 84.3339 84.9474 84.63 85.8358C84.9262 86.7242 84.9683 87.6776 84.7517 88.5887C84.3048 90.4204 83.4703 92.1351 82.3046 93.617C79.8768 97.308 76.8054 100.533 73.2369 103.137C69.5663 105.668 66.2308 107.311 61.8227 106.657"
      fill="#242E49"
    />
    <path
      d="M59.4595 71.8611C61.9 64.5658 66.2388 58.0517 72.0302 52.9882C72.9537 52.0592 74.1124 51.3987 75.3824 51.0775C76.6565 50.9772 77.9257 51.3239 78.9719 52.0581C80.018 52.7922 80.7757 53.8679 81.1147 55.1001C81.5162 56.4802 81.6222 57.9294 81.4258 59.3533C81.2294 60.7771 80.735 62.1435 79.9749 63.3633C78.4535 65.8064 76.459 67.9207 74.1086 69.5817C69.8975 72.7949 65.209 75.3285 60.2137 77.0906"
      fill="#242E49"
    />
    <path
      d="M54.4646 44.4735C55.6714 35.6404 60.2639 28.2823 65.5939 21.1422C66.7169 19.6337 68.2924 18.0414 70.1361 18.3263C70.7237 18.4494 71.271 18.718 71.7278 19.1075C72.1846 19.497 72.5364 19.9949 72.7508 20.5556C73.165 21.6858 73.3308 22.892 73.2369 24.0921C73.0084 29.4442 71.2076 34.6102 68.0596 38.9446C64.9117 43.279 60.5561 46.5896 55.5373 48.4626"
      fill="#242E49"
    />
    <path
      d="M50.9616 30.3941C51.5393 25.8705 51.6908 21.3026 51.4142 16.7506C51.1304 12.1692 49.562 7.76041 46.8887 4.02905C45.7715 2.42802 44.2265 1.17345 42.4303 0.408669C41.5173 0.0441407 40.526 -0.079456 39.5515 0.0496946C38.577 0.178845 37.6521 0.556407 36.8656 1.14616C36.2004 1.76989 35.6834 2.53483 35.3527 3.38471C35.022 4.23459 34.8861 5.14777 34.9549 6.05712C35.1225 7.86781 35.5986 9.63652 36.3628 11.2866C39.715 19.2983 45.2461 28.1649 51.0454 30.3941"
      fill="#242E49"
    />
    <path
      d="M105.401 40.9204C105.362 41.2937 105.362 41.6701 105.401 42.0434C105.401 42.8647 105.401 43.9206 105.401 45.2447C105.401 46.5688 105.401 48.3455 105.318 50.273C105.234 52.2005 105.318 54.4968 105.083 56.9774C104.865 62.0057 104.379 67.8386 103.625 74.3753C102.871 80.9121 101.597 88.0523 100.071 95.511C98.5462 102.97 96.7193 109.925 94.7583 116.228C92.7972 122.53 90.8865 128.094 89.093 132.737C88.255 135.067 87.4169 137.145 86.6292 138.972C85.8414 140.799 85.2212 142.324 84.6514 143.649L83.411 146.598C83.2475 146.938 83.1129 147.292 83.0088 147.654C83.2242 147.343 83.4094 147.012 83.5619 146.666C83.9139 145.928 84.3664 144.989 84.9531 143.783C85.5397 142.576 86.2772 140.984 87.0482 139.157C87.8192 137.33 88.7243 135.285 89.6461 132.955C91.5066 128.329 93.5012 122.731 95.479 116.445C97.4568 110.16 99.2837 103.12 100.876 95.6786C102.468 88.2367 103.507 81.063 104.228 74.5095C104.949 67.9559 105.368 62.0393 105.502 57.0445C105.636 54.5639 105.603 52.3011 105.619 50.3401C105.636 48.379 105.619 46.6527 105.619 45.3118C105.619 43.9709 105.619 42.9317 105.502 42.1104C105.517 41.7111 105.483 41.3115 105.401 40.9204Z"
      fill="#242E49"
    />
    <path
      d="M103.44 77.1234C103.355 72.8147 101.78 68.6686 98.9819 65.3907C98.5705 64.7874 98.0181 64.2934 97.3726 63.9517C96.7271 63.61 96.0081 63.4308 95.2778 63.4297C93.3502 63.7146 92.4787 66.0109 92.4116 67.9552C92.309 71.2564 93.4045 74.4832 95.4957 77.0396C97.2793 79.4968 99.9588 81.1527 102.954 81.6489"
      fill="#242E49"
    />
    <path
      d="M99.1997 100.656C99.4344 95.6279 97.8085 87.7838 94.8586 83.6773C94.4173 83.0124 93.8474 82.4426 93.1825 82.0012C92.8457 81.7816 92.46 81.6484 92.0595 81.6133C91.659 81.5783 91.2561 81.6424 90.8863 81.8C90.4126 82.1234 90.0298 82.5629 89.7745 83.0764C89.5192 83.5899 89.3999 84.1604 89.428 84.7332C89.1459 88.3855 89.8854 92.0442 91.5637 95.3002C93.242 98.5563 95.7931 101.281 98.9315 103.17"
      fill="#242E49"
    />
    <path
      d="M93.7023 120.803C93.1977 115.74 91.352 110.903 88.3555 106.791C88.0555 106.278 87.6565 105.83 87.1817 105.473C86.7069 105.117 86.1658 104.858 85.59 104.712C83.8133 104.478 82.3886 106.305 82.0031 108.065C81.1986 111.668 82.992 115.339 85.0704 118.406C87.062 120.973 89.3775 123.272 91.9592 125.245"
      fill="#242E49"
    />
    <path
      d="M88.7411 134.983C87.9551 131.001 86.3012 127.241 83.8971 123.971C83.3747 123.275 82.7051 122.702 81.9361 122.295C81.5453 122.091 81.1064 121.997 80.6665 122.024C80.2266 122.051 79.8022 122.196 79.4387 122.445C78.3325 123.317 78.5839 125.043 79.0532 126.384C80.5641 130.964 83.5008 134.94 87.4337 137.732"
      fill="#242E49"
    />
    <path
      d="M91.0372 129.267C95.5628 126.298 100.355 123.756 105.351 121.674C105.709 121.45 106.118 121.319 106.541 121.296C106.963 121.272 107.384 121.357 107.765 121.54C108.001 121.742 108.176 122.006 108.271 122.302C108.365 122.598 108.376 122.915 108.301 123.216C108.105 123.844 107.766 124.417 107.312 124.892C105.111 127.362 102.381 129.304 99.3247 130.572C96.2688 131.84 92.9662 132.403 89.6628 132.217"
      fill="#242E49"
    />
    <path
      d="M96.4846 110.545C100.418 107.997 104.329 105.438 108.217 102.868C109.302 102.087 110.524 101.518 111.821 101.192C112.465 100.969 113.163 100.955 113.816 101.153C114.468 101.35 115.042 101.749 115.455 102.291C115.867 102.834 116.098 103.493 116.114 104.175C116.129 104.856 115.929 105.525 115.542 106.086C114.838 107.23 113.901 108.212 112.793 108.969C110.338 111.002 107.532 112.57 104.513 113.595C103.167 114.168 101.719 114.463 100.256 114.463C98.7928 114.463 97.3448 114.168 95.9985 113.595"
      fill="#242E49"
    />
    <path
      d="M101.932 89.2253C105.172 84.7882 109.526 81.2845 114.553 79.0681C115.372 78.6531 116.283 78.4513 117.201 78.4815C118.09 78.6874 118.879 79.1967 119.433 79.9218C119.987 80.6468 120.27 81.5422 120.235 82.4538C120.206 83.4799 119.964 84.4886 119.522 85.4152C119.08 86.3418 118.45 87.1657 117.67 87.8341C116.111 89.1706 114.298 90.1796 112.34 90.8008C108.782 92.0865 105.043 92.7993 101.261 92.9127"
      fill="#242E49"
    />
    <path
      d="M104.446 69.4639C107.161 63.7148 111.871 59.6922 117.034 55.9712C117.515 55.5049 118.111 55.1729 118.761 55.0082C119.41 54.8435 120.092 54.8519 120.738 55.0326C121.112 55.2421 121.428 55.5421 121.656 55.9052C121.885 56.2683 122.018 56.6829 122.045 57.111C122.085 57.9708 121.936 58.8289 121.609 59.6252C120.301 63.22 117.963 66.3499 114.886 68.6242C111.81 70.8985 108.133 72.2166 104.312 72.4138"
      fill="#242E49"
    />
    <path
      d="M105.083 59.1732C106.449 56.2147 107.532 53.1337 108.318 49.9714C109.114 46.7913 108.998 43.4509 107.982 40.3338C107.535 39.0247 106.734 37.8646 105.669 36.9816C105.126 36.5353 104.476 36.2361 103.784 36.1128C103.091 35.9896 102.378 36.0463 101.714 36.2777C101.128 36.5596 100.613 36.9697 100.207 37.4773C99.8006 37.985 99.5137 38.5774 99.3672 39.2108C99.0765 40.4748 99.0197 41.7814 99.1996 43.0659C99.7359 49.2339 101.613 56.4747 105.083 59.257"
      fill="#242E49"
    />
    <path
      d="M16.6353 40.1489C16.6102 40.5228 16.6102 40.898 16.6353 41.2719C16.6353 42.0932 16.5515 43.1491 16.518 44.4732C16.4844 45.7974 16.4174 47.574 16.518 49.5015C16.6185 51.4291 16.518 53.7421 16.6353 56.2059C16.7861 61.2342 17.2052 67.1174 17.9091 73.6709C18.6131 80.2245 19.7863 87.3981 21.2613 94.84C22.7363 102.282 24.7141 109.305 26.6416 115.607C28.5691 121.909 30.614 127.49 32.4912 132.117C33.3795 134.446 34.3014 136.491 35.0891 138.318C35.8769 140.145 36.5641 141.67 37.1843 142.944L38.5754 145.827C38.7191 146.175 38.8988 146.506 39.1118 146.816C39.0098 146.455 38.881 146.102 38.7263 145.76L37.4022 142.877C36.8323 141.603 36.1618 140.044 35.4411 138.201C34.7204 136.357 33.765 134.295 32.9605 131.966C31.1671 127.323 29.2396 121.725 27.2953 115.456C25.351 109.187 23.5576 102.165 21.982 94.7562C20.4065 87.3479 19.3338 80.1909 18.5125 73.6709C17.6912 67.1509 17.289 61.2342 17.0711 56.2059C16.9035 53.7253 16.8867 51.4793 16.8197 49.5015C16.7526 47.5237 16.8197 45.8141 16.8197 44.4732C16.8197 43.1324 16.8197 42.0932 16.8197 41.2719C16.8059 40.8913 16.7439 40.514 16.6353 40.1489Z"
      fill="#00737D"
    />
    <path
      d="M18.5964 76.3192C18.6806 72.0074 20.2628 67.8592 23.0716 64.5865C23.4784 63.9824 24.0274 63.4876 24.6705 63.1456C25.3135 62.8036 26.0307 62.625 26.759 62.6255C28.6866 62.9104 29.5581 65.2067 29.6252 67.151C29.7545 70.4788 28.6637 73.7392 26.5579 76.3192C24.7691 78.7788 22.0834 80.4348 19.0825 80.9285"
      fill="#00737D"
    />
    <path
      d="M22.8368 99.8857C22.6021 94.8574 24.2279 87.0133 27.1779 82.9068C27.6203 82.2428 28.19 81.6731 28.854 81.2307C29.1876 81.0112 29.5705 80.8779 29.9684 80.8428C30.3662 80.8077 30.7665 80.8719 31.1335 81.0296C31.6071 81.3529 31.9899 81.7924 32.2452 82.3059C32.5005 82.8195 32.6198 83.3899 32.5917 83.9628C32.8777 87.6155 32.14 91.2757 30.4613 94.5325C28.7826 97.7893 26.2293 100.514 23.0882 102.4"
      fill="#00737D"
    />
    <path
      d="M28.3345 120.032C28.8527 114.97 30.7034 110.135 33.698 106.02C33.9954 105.509 34.3915 105.062 34.8635 104.705C35.3354 104.348 35.8736 104.089 36.4468 103.942C38.2402 103.707 39.6481 105.534 40.0504 107.294C40.8549 110.898 39.0615 114.585 36.9664 117.636C34.9794 120.207 32.6634 122.506 30.0776 124.474"
      fill="#00737D"
    />
    <path
      d="M33.3123 134.195C34.0927 130.232 35.7475 126.493 38.1562 123.25C38.6726 122.555 39.3366 121.982 40.1005 121.574C40.4913 121.37 40.9301 121.277 41.3701 121.303C41.81 121.33 42.2344 121.476 42.5978 121.725C43.7041 122.596 43.4694 124.339 43.0001 125.664C41.4819 130.24 38.5467 134.214 34.6196 137.011"
      fill="#00737D"
    />
    <path
      d="M31.1 128.496C26.5686 125.527 21.7711 122.985 16.7693 120.903C16.4113 120.679 16.002 120.548 15.5799 120.525C15.1577 120.501 14.7365 120.585 14.3558 120.769C14.1074 120.98 13.926 121.259 13.8337 121.572C13.7414 121.884 13.7423 122.217 13.8362 122.529C14.0265 123.155 14.3593 123.729 14.8083 124.205C17.0104 126.674 19.7409 128.615 22.7965 129.883C25.8522 131.151 29.1544 131.714 32.4576 131.53"
      fill="#00737D"
    />
    <path
      d="M25.5518 109.841L13.8191 102.165C12.7389 101.386 11.5222 100.818 10.2323 100.488C9.58298 100.319 8.89959 100.333 8.25768 100.528C7.61578 100.724 7.04038 101.093 6.59514 101.595C6.22523 102.154 6.02107 102.806 6.00624 103.477C5.99141 104.147 6.16652 104.808 6.51134 105.383C7.20565 106.484 8.11837 107.431 9.1931 108.165C11.6509 110.194 14.4563 111.762 17.473 112.791C18.8222 113.364 20.2729 113.659 21.7387 113.659C23.2045 113.659 24.6552 113.364 26.0044 112.791"
      fill="#00737D"
    />
    <path
      d="M20.1046 88.4543C16.872 84.01 12.5164 80.5046 7.4836 78.2971C6.67019 77.8821 5.76478 77.6803 4.85212 77.7105C3.96183 77.916 3.17069 78.4247 2.61418 79.1494C2.05768 79.8741 1.77042 80.7697 1.80161 81.6829C1.83014 82.7089 2.07304 83.7177 2.51468 84.6443C2.95633 85.5709 3.58697 86.3948 4.36605 87.0631C5.92562 88.3997 7.73829 89.4086 9.69605 90.0298C13.2541 91.3155 16.9936 92.0284 20.7751 92.1417"
      fill="#00737D"
    />
    <path
      d="M17.6071 68.6934C14.8751 62.9444 10.1652 58.9217 5.00286 55.2008C4.52212 54.733 3.92672 54.4 3.27651 54.2352C2.62629 54.0705 1.94417 54.0798 1.29868 54.2622C0.928444 54.4748 0.61675 54.776 0.391512 55.1387C0.166274 55.5014 0.0345258 55.9144 0.00808126 56.3405C-0.0376437 57.1991 0.105388 58.0573 0.427106 58.8547C1.7375 62.4483 4.07624 65.577 7.15198 67.8511C10.2277 70.1251 13.9046 71.4439 17.7245 71.6433"
      fill="#00737D"
    />
    <path
      d="M16.9704 58.4016C15.5992 55.4435 14.5105 52.3626 13.7188 49.1998C12.9274 46.0181 13.0494 42.6777 14.0707 39.5622C14.4858 38.2044 15.29 36.998 16.3838 36.0927C16.9242 35.6463 17.5707 35.347 18.2608 35.2237C18.9508 35.1004 19.661 35.1572 20.3226 35.3887C20.9108 35.6697 21.4285 36.0792 21.8375 36.5868C22.2465 37.0944 22.5364 37.6873 22.6859 38.3219C22.9598 39.5881 23.0165 40.8917 22.8535 42.1769C22.3004 48.3618 20.4399 55.5857 16.9704 58.3681"
      fill="#00737D"
    />
    <path
      d="M120.402 146.883V161.8C120.402 162.568 120.251 163.329 119.957 164.039C119.663 164.748 119.232 165.393 118.689 165.936C118.146 166.48 117.501 166.91 116.791 167.204C116.082 167.498 115.321 167.65 114.553 167.65H12.8974C12.1293 167.65 11.3686 167.498 10.6589 167.204C9.94919 166.91 9.30434 166.48 8.76116 165.936C8.21797 165.393 7.78709 164.748 7.49312 164.039C7.19915 163.329 7.04785 162.568 7.04785 161.8V146.883H120.402Z"
      fill="#08B4BD"
    />
    <g opacity={0.4}>
      <path
        d="M111.586 167.583L110.748 172.31L16.9538 178.997L14.5737 167.583H111.586Z"
        fill="black"
      />
    </g>
  </svg>
);
export default PlantPot2;
