import { QUESTIONS } from './constants';
import MagicStar from '../../assets/SVGs/MagicStar';
import { AskSandra } from './AskSandra';
import { useNavigate } from 'react-router-dom';
export const SandraQnA = () => {
  const navigate = useNavigate();
  return (
    <>
      <AskSandra />
      <div className="flex flex-wrap justify-center gap-4 mt-4 max-w-4xl">
        {QUESTIONS.map((question, index) => (
          <button
            key={index}
            style={{
              background:
                'linear-gradient(100.06deg, #DCE1E8 12.16%, #CAD3E1 95.67%)',
            }}
            className="px-6 py-2 bg-white text-gray-800 flex rounded-lg flex-wrap justify-center items-center shadow-md hover:bg-teal-50 hover:text-teal-600 transition-all"
            onClick={() => {
              navigate('/asksandra', {
                state: {
                  question: question.question,
                  answer: question.answer,
                  date: Date.now(),
                },
              });
            }}
          >
            <MagicStar />
            <span className="ml-2">{question.question}</span>
          </button>
        ))}
      </div>
    </>
  );
};
