import React from 'react';
import ChatBubble from './ChatBubble';
// import ComponentRegistry from './ComponentRegistry';
import ChatLoading from './ChatLoading';
import { useChatContext } from '../../context/ChatContext';

type Message = {
  type: 'text' | 'button';
  content: string;
  sender: 'user' | 'assistant';
};

type MessageDisplayProps = {
  message: Message | null;
  chatScrollUp: any;
  isChatLoading?: boolean;
  setIsTypingCompleted?: Function | null;
  isTypingCompleted?: boolean;
};

const DynamicResponseRenderer: React.FC<MessageDisplayProps> = ({
  message,
  chatScrollUp,
  isChatLoading,
  setIsTypingCompleted,
  isTypingCompleted,
}) => {
  const { sendMessageWithResponse } = useChatContext();
  if (isChatLoading) {
    return <ChatLoading />;
  }
  if (!message || !message.content) {
    console.warn(
      'DynamicResponseRenderer received an invalid message:',
      message
    );
    return null;
  }
  const Placeholder: React.FC = () => null;
  const Component: React.ComponentType<any> = Placeholder;

  // const Component: React.ComponentType<any> = null;// message?.content.indexOf('Sandra') ? ComponentRegistry['UserEligibilityForm'] : null;
  return (
    <>
      <ChatBubble
        chatScrollUp={chatScrollUp}
        text={message.content}
        sender={message.sender}
        type={message.type}
        setIsTypingCompleted={setIsTypingCompleted}
      />
      {isTypingCompleted && Component ? (
        <Component
          callback={(value: any) => {
            const subs = value.primaryInsuranceSubscriber
              ? `I'm the subscriber`
              : `I'm not the subscriber`;
            const res = `My membership Id ${value.membershipId}, insurance company name is ${value.insuranceCompanyName}, ${subs}, Date of birth of insurance subsriber is ${value.dateOfBirth}`;
            console.log(value);
            sendMessageWithResponse(res);
          }}
        />
      ) : null}
    </>
  );
};

export default DynamicResponseRenderer;
