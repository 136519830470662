import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MgicStartWithSandraDocIcon from '../shared/MgicStartWithSandraDocIcon';
const MultilineInputBox = () => {
  const [text, setText] = useState('');
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    autoResize();
  };
  const autoResize = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > 100) {
        textareaRef.current.style.height = '100px';
      }
    }
  };
  const handleSendMessage = () => {
    if (text.trim()) {
      navigate('/asksandra', {
        state: {
          question: text.trim(),
          date: Date.now(),
        },
      });
    }
    setText('');
    autoResize();
  };
  return (
    <div className="flex flex-col items-center w-full">
      <MgicStartWithSandraDocIcon />
      <div className="flex items-center bg-white border border-teal-300 rounded-lg shadow-sm px-4 w-full max-w-lg">
        <textarea
          type="text"
          ref={textareaRef}
          value={text}
          // @ts-ignore
          onChange={handleInputChange}
          placeholder="Ask a question..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          className="w-full outline-none text-gray-600 placeholder-gray-400 focus:ring-0"
          rows={1}
          style={{
            overflowY: 'auto',
            maxHeight: '100px',
          }}
        />
        <button
          className="p-2  rounded-full text-white"
          onClick={handleSendMessage}
        >
          <div
            className={`flex items-center justify-center w-10 h-10 rounded-lg ${text?.length > 0 ? 'bg-[#0699A1]' : 'bg-gray-200'}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              className="w-5 h-5 text-gray-500"
            >
              <path
                d="M12 20V7m0 0l-5 5m5-5l5 5"
                stroke={`${text.length > 0 ? 'white' : 'black'}`}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

export default MultilineInputBox;
