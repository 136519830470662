import ArrowIcon from '../../assets/SVGs/ArrowIcon';
import PlantPot1 from '../../assets/SVGs/PlantPot1';
import PlantPot2 from '../../assets/SVGs/PlantPot2';
import SandraDoc from '../../assets/SVGs/SandraDoc';
// import SandraDoctor from '../../assets/SVGs/SandraDoctor';
// import { AskSandra } from './AskSandra';
import MultilineInputBox from './MultilineInputBox';
import QuestionTabs from './QuestionTabs';
import { SandraQnA } from './SandraQnA';
import { LOGGED_IN_QUESTIONS } from './constants';
type HomeProps = {
  isAuthenticated: boolean;
};
const Home: React.FC<HomeProps> = ({ isAuthenticated }) => {
  return (
    <div className="min-h-[950px] md:min-h-screen overflow-hidden relative flex flex-col items-center bg-gray-10 p-4 md:p-8">
      {!isAuthenticated && (
        <>
          <div className="flex justify-center items-center w-full md:justify-end md:h-auto"></div>
          <SandraQnA />
          <div className="md:block absolute bottom-20 md:bottom-40 z-10">
            <button
              style={{ whiteSpace: 'nowrap' }}
              className="px-6 md:px-24 py-4 bg-teal-40 font-bold text-white text-sm md:text-lg rounded-[16px] w-full md:w-auto flex items-center justify-center"
              onClick={() => {
                window.location.href = '/signup';
              }}
            >
              CREATE A FREE ACCOUNT{' '}
              <ArrowIcon style={{ marginLeft: '8px' }} color="white" />
            </button>
            <div className="bg-white mt-6 rounded-[16px] z-10 w-full border border-teal-40">
              <button
                className="px-6 md:px-32 py-4 bg-white text-teal-40 font-bold rounded-[16px] text-sm md:text-lg w-full flex items-center justify-center"
                onClick={() => {
                  window.location.href = '/login';
                }}
              >
                SIGN IN <ArrowIcon style={{ marginLeft: '8px' }} />
              </button>
            </div>
          </div>
          <div className="absolute bottom-20 md:bottom-0 right-45 md:right-0 w-[157px] md:w-[257px] z-0">
            <SandraDoc />
          </div>
          <div className="absolute bottom-0 left-10 flex justify-end items-end hidden md:flex">
            <PlantPot1 />
            <div className="ml-5">
              <PlantPot2 />
            </div>
          </div>
        </>
      )}

      {isAuthenticated && (
        <>
          <div className="p-2 sm:p-4 md:p-8 lg:p-10 xl:p-16">
            {/* <AskSandra />{' '} */}
          </div>
          <MultilineInputBox />
          <QuestionTabs data={LOGGED_IN_QUESTIONS} />
        </>
      )}
    </div>
  );
};

export default Home;
