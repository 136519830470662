// import React from 'react';
// import MagicStar from '../../assets/SVGs/MagicStar';
import SandraDoctorWithWhiteBackground from '../../assets/SVGs/SandraDoctorWithWhiteBackground';
import MultipleMagicStar from '../../assets/SVGs/MultipleMagicStar';

export default function MgicStartWithSandraDocIcon() {
  return (
    <div className="w-auto h-auto relative">
      <div>
        <SandraDoctorWithWhiteBackground />
      </div>
      <div className="absolute top-16 left-[-15px] ">
        <MultipleMagicStar />
      </div>
    </div>
  );
}
