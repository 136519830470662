import * as React from 'react';
import { SVG } from '../../utils/types';

const Burger: React.FC<SVG> = ({
  width = 18,
  height = 12,
  // color = '#FFFFFF',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M17.5 10.5V11.5H0.5V10.5H17.5ZM17.5 5.5V6.5H0.5V5.5H17.5ZM17.5 1.5H0.5V0.5H17.5V1.5Z"
      fill="black"
      stroke="#3D4966"
    />
  </svg>
);
export default Burger;
