import * as React from 'react';
import { SVG } from '../../utils/types';

const MagicStar: React.FC<SVG> = ({
  width = 20,
  height = 20,
  color = '#9EA7B8',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M10.001 0L13.478 6.52129L20 10L13.478 13.4787L10.001 20L6.52197 13.4787L0 10L6.52197 6.52129L10.001 0Z"
      fill="url(#paint0_linear_2625_22314)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2625_22314"
        x1={1.21622}
        y1={-4.70588}
        x2={22.3322}
        y2={-2.94348}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42CBD2" />
        <stop offset={1} stopColor="#0699A1" />
      </linearGradient>
    </defs>
  </svg>
);
export default MagicStar;
