import Bulb from './Bulb';
import Document from './Document';
import Gear from './Gear';
import MagicStar from './MagicStar';
import Matrix from './Matrix';
import Monitor from './Monitor';
// import MultipleMagicStar from './MultipleMagicStar';
import QA from './QA';
import QAColored from './QAColored';
// import SandraDoctor from './SandraDoctor';
import SidebarLogo from './SidebarLogo';
import SignOut from './SignOut';
import Spot from './Spot';
import StarGreen from './StarGreen';
import YellowDollor from './YellowDollor';
import { SVGComponent as Sandra } from '../SandraChatLogo';
import Bill from './Bill';
import Dollar from './Dollar';
import Wallet from './Wallet';
import Insurance from './Insurance';
import Home from './Home';
import ArrowIcon from './ArrowIcon';

export const svgNameMap: { [key: string]: any } = {
  SidebarLogo: SidebarLogo,
  Gear: Gear,
  MagicStar: MagicStar,
  Bulb: Bulb,
  Document: Document,
  Matrix: Matrix,
  QA: QA,
  SignOut: SignOut,
  Spot: Spot,
  YellowDollor: YellowDollor,
  Monitor: Monitor,
  QAColored: QAColored,
  StarGreen: StarGreen,
  Sandra: Sandra,
  Bill: Bill,
  Dollar: Dollar,
  Wallet: Wallet,
  Insurance: Insurance,
  Home: Home,
  ArrowIcon: ArrowIcon,
};
