import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Sidebar from './components/sidebar/Sidebar';
import AppRoutes from './router';
import { hideSidenav } from './utils/utils'; // getCookie
import MobileHeader from './components/sidebar/MobileHeader';
import { useAuthContext } from './context/AuthContext';

const queryClient = new QueryClient();

function App() {
  const cookie = true; //getCookie("qwerty") === "stitchfin";
  const { isAuthenticated } = useAuthContext();
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <MobileHeader />
        <div className="App flex w-full h-auto">
          {hideSidenav() && isAuthenticated && <Sidebar />}
          <div className="w-full bg-[#F2F5F9]">
            <AppRoutes isValidCookie={cookie} />
          </div>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
