import * as React from 'react';
import { SVG } from '../../utils/types';

const Insurance: React.FC<SVG> = ({
  width = 28,
  height = 29,
  color = '#9EA7B8',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <rect
      x="4.66669"
      y="4.5"
      width="18.6667"
      height="19.8333"
      rx="3"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M10.5 4.5V2.5C10.5 1.67157 11.1716 1 12 1H16C16.8284 1 17.5 1.67157 17.5 2.5V4.5"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M7 5.66663V7.66663C7 8.49505 7.67157 9.16663 8.5 9.16663H19.5C20.3284 9.16663 21 8.49505 21 7.66663V5.66663"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

export default Insurance;
