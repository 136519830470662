import React from 'react';

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="loader border-t-4 border-teal-500 border-solid rounded-full w-16 h-16 animate-spin"></div>
    </div>
  );
};

export default LoadingOverlay;
