import React, { useState } from 'react';
// import SidebarLogo from '../../assets/SVGs/SidebarLogo';
import LoginSideImg from '../../assets/SVGs/LogInSideImage';
import { useLocation } from 'react-router-dom';
import { getTimeDifference } from './../../utils/utils'; // goBack

const Login: React.FC<{
  login: (username: string, password: string) => void;
}> = ({ login }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();
  const { signUp, date } = location.state || {};

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    login(username.trim(), password);
  };

  return (
    <div className="min-h-screen flex flex-col overflow-hidden">
      {/* Main Content */}
      <div className="flex flex-1">
        {/* Left Side: Sign-in Form */}
        <div className="flex flex-col justify-start md:justify-center w-full md:w-[55%] bg-[#F2F5F9] p-6 lg:p-12 sm:mt-0">
          <div className="sm:px-[32px] md:px-[64px]">
            {/* <span
              className="text-gray-600 cursor-pointer text-xl font-bold"
              onClick={goBack}
            >
              ←
            </span>
            <div className="flex justify-center items-center">
              <SidebarLogo />
            </div> */}
            <h2 className="mt-10 text-left text-2xl font-semibold leading-9 text-[#29406B] sm:mt-0">
              Sign in
            </h2>
          </div>
          <div className="mt-6 sm:px-[32px] md:px-[64px]">
            {signUp && date && getTimeDifference(date) && (
              <div
                className={`p-4 rounded-md mb-4 bg-green-100 text-green-700`}
              >
                Account created successfully!
              </div>
            )}
          </div>
          <div className="mt-10 sm:px-[32px] md:px-[64px] sm:mt-0">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="username"
                  className="font-semibold text-base text-[#29406B]"
                >
                  Username
                </label>
                <div className="mt-2">
                  <input
                    id="username"
                    name="username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="font-semibold text-base text-[#29406B]"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                  />
                </div>
                <div className="w-full text-right mt-2">
                  <label className="w-full text-sm text-right font-medium text-[#E24E40]">
                    Forgot password
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex px-4 py-3 w-full justify-center rounded-md bg-teal-40 px-3 py-1.5 text-xl font-semibold text-[#FFFFFF] shadow-sm"
                >
                  Sign In
                </button>
              </div>

              <p className="mt-10 text-center text-lg font-semibold">
                Don't have an account?{' '}
                <a
                  href="/signup"
                  className="text-lg text-[#3BA3AB] underline font-semibold cursor-pointer"
                >
                  Sign up
                </a>
              </p>
            </form>
          </div>
        </div>

        <div className="hidden md:flex md:w-[45%] items-center justify-center bg-[#242E49] h-screen">
          <LoginSideImg />
        </div>
      </div>
    </div>
  );
};

export default Login;
