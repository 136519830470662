import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './components/login/login';
import { loginApi, setCookie } from './utils/utils';
import Home from './components/home/home';
import Chat from './components/chat';
import Setting from './components/settings';
import WorkInProgress from './components/WIP';
import { useAuthContext } from './context/AuthContext';
import { useDispatch } from 'react-redux';
import { openSideBar } from './store/sidebarSlice';
import Signup from './components/signup/Signup';
import { SESSION_TIMEOUT_DAY } from './../src/utils/constants';
import Insurance from './components/insurance';
// import Bill from './components/bills'

type AppRouterType = {
  isValidCookie: boolean;
};

const AppRoutes: React.FC<AppRouterType> = ({ isValidCookie }) => {
  const { setIsAuthenticated, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const login = async (username: string, password: string) => {
    if (username !== '' && password !== '') {
      const response = await loginApi(username, password);
      const currentTime = new Date().getTime();
      setCookie(response?.data.accessToken);
      dispatch(openSideBar());
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('idToken', response.data.idToken);
      localStorage.setItem('username', response.data.user.username);
      localStorage.setItem('authTimestamp', currentTime.toString());
      setIsAuthenticated(true);
      navigate('/');
    } else {
      alert('username or password should not empty');
    }
  };

  useEffect(() => {
    const isAuth = localStorage.getItem('isAuthenticated') || isAuthenticated;
    const authTimestamp = localStorage.getItem('authTimestamp');
    const currentTime = new Date().getTime();

    if (
      isAuth === 'true' &&
      authTimestamp &&
      currentTime - parseInt(authTimestamp) < SESSION_TIMEOUT_DAY
    ) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('authTimestamp');
      // dispatch(hideSideBar())
      // navigate('/login');
    }
  }, [isAuthenticated, setIsAuthenticated]);

  if (!isValidCookie) {
    return <WorkInProgress />; // Show Work In Progress component when the cookie is invalid or not set.
  }
  // if( !isAuthenticated ) {
  //   return <Login login={login} />
  // }

  return (
    <Routes>
      <Route path="/" element={<Home isAuthenticated={isAuthenticated} />} />
      <Route path="/login" element={<Login login={login} />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/about" element={<>Hello About us</>} />
      <Route path="/contact" element={<>Hello contact us</>} />
      <Route path="/asksandra" element={<Chat />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/insurance" element={<Insurance />} />
      {/* <Route path="/bills" element={<Bill />} /> */}
    </Routes>
  );
};

export default AppRoutes;
