import React, { useState } from 'react';
import { useChatContext } from '../../context/ChatContext';

type MultiSelectProps = {
  message: { id: string; content: string; sender: 'user' | 'assistant' };
};

const MultiSelect: React.FC<MultiSelectProps> = ({ message }) => {
  const {
    setNewMessage,
    setComponentMessage,
    setComponentName,
    setShouldComponentRender,
  } = useChatContext();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const options = ['Option 1', 'Option 2', 'Option 3'];

  const handleOptionChange = (option: string) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((o) => o !== option)
        : [...prev, option]
    );
  };

  const handleSubmit = () => {
    const selectedMessage = `Selected options: ${selectedOptions.join(', ')}`;
    const newMessage = {
      id: `${Date.now()}`,
      type: 'text',
      content: selectedMessage,
      sender: 'user',
    };
    setNewMessage(newMessage);
    setComponentMessage(null);
    setComponentName(null);
    setShouldComponentRender(false);
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg">
      <p className="font-bold mb-2">Choose options:</p>
      <div className="space-y-2">
        {options.map((option) => (
          <label key={option} className="flex items-center space-x-2">
            <input
              className="border-none"
              type="checkbox"
              checked={selectedOptions.includes(option)}
              onChange={() => handleOptionChange(option)}
            />
            <span>{option}</span>
          </label>
        ))}
      </div>
      <button
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
        onClick={handleSubmit}
        disabled={selectedOptions.length === 0}
      >
        Submit
      </button>
    </div>
  );
};

export default MultiSelect;
