import MultipleMagicStar from '../../assets/SVGs/MultipleMagicStar';
import PatientBudLogo from '../../assets/SVGs/PatientBudLogo';
export const AskSandra = () => {
  return (
    <>
      <div className="flex md:mt-2 flex-col flex-wrap items-center mt-4">
        <div className="mb-5">
          <PatientBudLogo />
        </div>
        <MultipleMagicStar />
        <h1 className="text-2xl md:text-3xl font-bold text-center mt-4 text-gray-70">
          Healthcare Billing Made Simple
        </h1>
        <div className="text-center font-normal text-l p-2 rounded-lg space-x-2 md:text-2xl md:space-x-6 md:mt-4 md:mb-4 inline text-teal-40 max-w-[722px]">
          PatientBud simplifies healthcare billing—get clear, reliable answers
          in seconds.
        </div>
      </div>
    </>
  );
};
