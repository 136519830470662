import * as React from 'react';
import { SVG } from '../../utils/types';

const StarGreen: React.FC<SVG> = ({
  width = 37,
  height = 36,
  color = '#35C480',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M18.5 26.2794L24.725 30.0444C25.865 30.7344 27.26 29.7144 26.96 28.4244L25.31 21.3444L30.815 16.5744C31.82 15.7044 31.28 14.0544 29.96 13.9494L22.715 13.3344L19.88 6.6444C19.37 5.4294 17.63 5.4294 17.12 6.6444L14.285 13.3194L7.03999 13.9344C5.71999 14.0394 5.17999 15.6894 6.18499 16.5594L11.69 21.3294L10.04 28.4094C9.73999 29.6994 11.135 30.7194 12.275 30.0294L18.5 26.2794Z"
      fill="url(#paint0_linear_1_1227)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_1227"
        x1={5.66455}
        y1={5.73315}
        x2={30.1731}
        y2={31.3777}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color} />
        <stop offset={1} stopColor="#1F9A5F" />
      </linearGradient>
    </defs>
  </svg>
);
export default StarGreen;
