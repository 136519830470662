import SandraChatLogo from '../../assets/SandraChatLogo';

const ChatLoading = () => {
  const dotSize = 'w-2 h-2';
  const animationSpeed = '300ms';
  return (
    <div className="flex items-center justify-start">
      <SandraChatLogo />
      <div
        className="px-4 py-2 rounded-[16px] max-w-[90%] bg-white text-[#5D6A85] ml-3 flex items-center"
        style={{ padding: '15px' }}
      >
        <div className="flex items-center space-x-1">
          <div
            className={`${dotSize} bg-gray-400 rounded-full`}
            style={{ animation: `bounce ${animationSpeed} infinite alternate` }}
          ></div>
          <div
            className={`${dotSize} bg-gray-400 rounded-full`}
            style={{
              animation: `bounce ${animationSpeed} infinite alternate`,
              animationDelay: `calc(${animationSpeed} / 2)`,
            }}
          ></div>
          <div
            className={`${dotSize} bg-gray-400 rounded-full`}
            style={{
              animation: `bounce ${animationSpeed} infinite alternate`,
              animationDelay: `calc(${animationSpeed} * 1.5)`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ChatLoading;
