import * as React from 'react';
import { SVG } from '../../utils/types';

const Wallet: React.FC<SVG> = ({
  width = 28,
  height = 28,
  color = '#9EA7B8',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path d="M7 9.33337L23.3333 9.33338" stroke={color} strokeWidth="2" />
    <path
      d="M3.5 7.66662C3.5 6.00977 4.84315 4.66663 6.5 4.66663H18.8598C20.0418 4.66663 21 5.62484 21 6.80685V7.55915C21 8.48787 21.8339 9.19431 22.75 9.04163V9.04163C23.6661 8.88895 24.5 9.59538 24.5 10.5241V20.3333C24.5 21.9901 23.1569 23.3333 21.5 23.3333H6.5C4.84315 23.3333 3.5 21.9901 3.5 20.3333V7.66662Z"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

export default Wallet;
