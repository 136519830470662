import React from 'react';
// import SidebarLogo from '../../assets/SVGs/SidebarLogo';
import Burger from '../../assets/SVGs/Burger';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Cross from '../../assets/SVGs/Cross';
import { hideSideBar, openSideBar } from '../../store/sidebarSlice';
import AppleIcon from '../../assets/SVGs/AppleIcon';
import { useAuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PatientBudLogo from '../../assets/SVGs/PatientBudLogo';

const MobileHeader: React.FC = () => {
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const { isAuthenticated } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sideButtonClickHandle = () => {
    isOpen ? dispatch(hideSideBar()) : dispatch(openSideBar());
  };

  // Hide the header when authenticated and on the home page.
  if (!isAuthenticated && window.location.pathname === '/') {
    return null;
  }

  return (
    <header className="bg-white px-4 py-3 flex justify-between items-center lg:hidden">
      {/* Left Logo */}
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          navigate('/');
        }}
      >
        <PatientBudLogo width={153} />
      </div>

      {/* Center Get App Button */}
      <button className="flex items-center bg-teal-10 text-gray-800 px-3 py-1 rounded-full shadow-sm hover:bg-teal-50 transition">
        <AppleIcon />
        Get App
      </button>

      {/* Right Menu Icon */}
      {isAuthenticated && (
        <button
          onClick={sideButtonClickHandle}
          className="flex items-center justify-center w-10 h-10 bg-gray-10 rounded-lg shadow-sm"
        >
          {isOpen ? <Cross /> : <Burger />}
        </button>
      )}
    </header>
  );
};

export default MobileHeader;
