import React from 'react';

const WorkInProgress = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-5xl font-bold text-gray-800 mb-4">
          Work in Progress
        </h1>
        <p className="text-lg text-gray-600 mb-6">
          To access this page, you'll need some extraordinary powers. Do you
          have what it takes?
        </p>
      </div>
    </div>
  );
};

export default WorkInProgress;
