import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidebarState {
  isOpen: boolean;
  activeSidebarIconId: number;
}

const initialState: SidebarState = {
  isOpen: false,
  activeSidebarIconId: 1,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    openSideBar: (state) => {
      state.isOpen = true;
    },
    hideSideBar: (state) => {
      state.isOpen = false;
    },
    setActiveSidebarIconId: (state, action: PayloadAction<number>) => {
      state.activeSidebarIconId = action.payload;
    },
  },
});

export const { openSideBar, hideSideBar, setActiveSidebarIconId } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
