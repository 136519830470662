import axios from 'axios';
import { DOMAIN } from './constants';

export const getCookie = (name: string) => {
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    let cookie = cookieArr[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

// setCookie.js
export const setCookie = (
  value: string,
  name = 'Authorized',
  days = 1,
  secure = false,
  sameSite = 'Lax'
) => {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;
  const date = new Date();
  date.setDate(date.getDate() + days);
  cookieString += `expires=${date.toUTCString()};`;
  if (secure) {
    cookieString += 'Secure;';
  }
  cookieString += `SameSite=${sameSite};`;
  cookieString += 'path=/;';
  document.cookie = cookieString;
};

export const loginApi = async (username: string, password: string) => {
  try {
    const response = await axios.post(DOMAIN + '/auth/login', {
      username: username,
      password: password,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching total collection:', error);
    throw error;
  }
};

export type SignUpFormType = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
};

export const isValidSignUpFormData = (form: SignUpFormType) => {
  if (!form.firstName || !(form.firstName?.length > 2)) {
    return 'Please enter first name';
  }
  if (!form.lastName || !(form.lastName?.length > 2)) {
    return 'Please enter first name';
  }
  if (!form.userName || !(form.userName?.length > 2)) {
    return 'Please enter username';
  }
  if (!form.email || !form.email.includes('@')) {
    return 'Please enter valid email';
  }
  if (!form.password || !(form.password?.length > 8)) {
    return 'Please enter a password with at least 8 characters';
  }
  if (form.password !== form.confirmPassword) {
    return 'Passwords do not match';
  }
  if (!form.phoneNumber || !form.phoneNumber?.match(/^[0-9]{10}$/)) {
    return 'Please enter a valid phone number';
  }
  return null;
};

export const signUpApi = async (form: SignUpFormType) => {
  try {
    const response = await axios.post(DOMAIN + '/auth/signup', {
      firstName: form.firstName,
      lastName: form.lastName,
      username: form.userName,
      email: form.email,
      password: form.password,
      phone: form.phoneNumber,
    });
    return response.data;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export type UserEligibilityType = {
  membershipId: string;
  insuranceCompanyName: string;
  primaryInsuranceSubscriber: boolean;
  dateOfBirth: string;
};

export const postUserEligibilityData = async (form: UserEligibilityType) => {
  const token: any = await localStorage.getItem('idToken');
  try {
    const response = await axios.post(
      DOMAIN + '/user_eligibility_data/create',
      {
        membershipId: form.membershipId,
        insuranceCompanyName: form.insuranceCompanyName,
        primaryInsuranceSubscriber: form.primaryInsuranceSubscriber,
        userDob: form.dateOfBirth,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in post request of user eligibility api up:', error);
    throw error;
  }
};

export const getPayers = async () => {
  try {
    const response = await axios.get(DOMAIN + '/payers', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching payers:', error);
    throw error;
  }
};
export const confirmSignUpOtp = async (username: string, otp: string) => {
  try {
    const response = await axios.post(
      DOMAIN + `/auth/confirm?username=${username}&confirmationCode=${otp}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const hideSidenav = () => {
  if (window.location.pathname === '/login') {
    return false;
  }
  return true;
};

export const getTimeDifference = (date: Date | number): boolean => {
  const inputTime = typeof date === 'number' ? date : date.getTime();
  const differenceInMilliseconds = Math.abs(inputTime - Date.now());
  return differenceInMilliseconds >= 60000;
};

export const debounce = (func: Function, delay: number) => {
  let timer: any;
  // @ts-ignore
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
    }, delay);
  };
};

export const goBack = () => {
  if (window.history.length > 1) {
    window.history.back();
  } else {
    window.location.href = '/';
  }
};

export function throttle(func: Function, limit: number) {
  let lastFunc: NodeJS.Timeout;
  let lastRan: number;

  return function (this: any, ...args: any[]) {
    const context = this;
    const now = Date.now();

    if (!lastRan) {
      func.apply(context, args);
      lastRan = now;
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        () => {
          if (now - lastRan >= limit) {
            func.apply(context, args);
            lastRan = now;
          }
        },
        limit - (now - lastRan)
      );
    }
  };
}

export const logout = () => {
  document.cookie =
    'authorized=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  localStorage.setItem('isAuthenticated', 'false');
  localStorage.setItem('idToken', '');
  localStorage.setItem('username', '');
  localStorage.setItem('authTimestamp', new Date().getTime() + '');
  window.location.href = '/';
};
