import * as React from 'react';
import { SVG } from '../../utils/types';

const Dollar: React.FC<SVG> = ({
  width = 28,
  height = 28,
  color = '#9EA7B8',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M19.8334 10.5V8.5C19.8334 7.67157 19.1618 7 18.3334 7H9.66669C8.83826 7 8.16669 7.67157 8.16669 8.5V12.5C8.16669 13.3284 8.83826 14 9.66669 14H18.3334C19.1618 14 19.8334 14.6716 19.8334 15.5V19.5C19.8334 20.3284 19.1618 21 18.3334 21H9.66669C8.83826 21 8.16669 20.3284 8.16669 19.5V17.5"
      stroke={color}
      strokeWidth="2"
    />
    <path d="M11.6667 7V3.5" stroke={color} strokeWidth="2" />
    <path d="M16.3333 7V3.5" stroke={color} strokeWidth="2" />
    <path d="M11.6667 24.5V21" stroke={color} strokeWidth="2" />
    <path d="M16.3333 24.5V21" stroke={color} strokeWidth="2" />
  </svg>
);

export default Dollar;
