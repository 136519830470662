import * as React from 'react';
import { SVG } from '../../utils/types';

const QA: React.FC<SVG> = ({
  width = 32,
  height = 32,
  color = '#9EA7B8',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <mask id="path-1-inside-1_1_1177" fill="white">
      <path d="M20 5.33335V14.6667H6.89329L5.33329 16.2267V5.33335H20ZM21.3333 2.66669H3.99996C3.26663 2.66669 2.66663 3.26669 2.66663 4.00002V22.6667L7.99996 17.3334H21.3333C22.0666 17.3334 22.6666 16.7334 22.6666 16V4.00002C22.6666 3.26669 22.0666 2.66669 21.3333 2.66669ZM28 8.00002H25.3333V20H7.99996V22.6667C7.99996 23.4 8.59996 24 9.33329 24H24L29.3333 29.3334V9.33335C29.3333 8.60002 28.7333 8.00002 28 8.00002Z" />
    </mask>
    <path
      d="M20 5.33335V14.6667H6.89329L5.33329 16.2267V5.33335H20ZM21.3333 2.66669H3.99996C3.26663 2.66669 2.66663 3.26669 2.66663 4.00002V22.6667L7.99996 17.3334H21.3333C22.0666 17.3334 22.6666 16.7334 22.6666 16V4.00002C22.6666 3.26669 22.0666 2.66669 21.3333 2.66669ZM28 8.00002H25.3333V20H7.99996V22.6667C7.99996 23.4 8.59996 24 9.33329 24H24L29.3333 29.3334V9.33335C29.3333 8.60002 28.7333 8.00002 28 8.00002Z"
      fill="black"
      stroke={color}
      strokeWidth={5.34}
      mask="url(#path-1-inside-1_1_1177)"
    />
  </svg>
);
export default QA;
