export const QUESTIONS = [
  {
    id: 1,
    question: 'What is PatientBud?',
    answer: `PatientBud is a platform built for patients to ask any billing related question. We are your trusted guide, simplifying every step of the journey—from breaking down complex healthcare terms like deductibles, copays, and out-of-pocket maximums to addressing personalized questions about your eligibility, coverage, cost estimates, and bills. 

        <br / > Our mission is bold yet simple: to eliminate patient confusion, empowering you with clarity and confidence whether you’re visiting a doctor, clinic, private practice, or a large hospital. With PatientBud, navigating healthcare billing has never been easier! 
        
        <br / > So meet your friendly agent Sandra—ready to answer any question you have!
        `,
  },
  {
    id: 2,
    question: 'Why should I create an account or log in?',
    answer:
      'Creating an account or logging in unlocks a personalized experience tailored just for you! Gain access to your billing details, track your inquiries, save your preferences, and enjoy seamless support. It’s the easiest way to stay informed and in control of your healthcare billing journey!',
  },
  {
    id: 3,
    question: 'How can PatientBud help me?',
    answer: `<h2>Our AI agent Sandra can help you in the following ways:</h2>
        <ol>
            <li>Understanding healthcare terms like deductibles, copays, and out-of-pocket maximums.</li>
            <li>Checking your eligibility and coverage details for services.</li>
            <li>Getting cost estimates for procedures or visits.</li>
            <li>Reviewing your bills to understand charges.</li>
            <li>Your all-in-one hub to save and access your estimates, bills, and insurance details anytime!</li>
        </ol>`,
  },
];
export const LOGGED_IN_QUESTIONS = [
  'What is an EOB?',
  'How can I ensure my medical bill is accurate?',
  'What is a prior auth?',
  'What is my deductible?',
  'What is my out of pocket maximum?',
  'What medications are covered under my insurance plan?',
  // 'What are common medical billing errors to watch out for?',
  'Can you give me an estimate for a procedure?',
];
